import {computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {filterTariffs} from './utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {HodlTariffResponseItem} from './HodlsTariffsResponse';

export class HodlsTariffsResource extends createResource({
  getKey: () => 'HodlsTariffs',
  getData: () =>
    TRANSPORT.API.get(`/v3/hodl/tariffs`)
      .then(res => {
        return deserialize<HodlTariffResponseItem>(
          HodlTariffResponseItem,
          filterTariffs(res.data)
        );
      })
      .catch(error => {
        if (error.response?.status === 403) {
          return Promise.resolve([] as HodlTariffResponseItem[]);
        } else {
          throw error;
        }
      }),
}) {
  // NOTE: function getTermsWithWhiteList was deleted as BE filters the tariffs on their side
  @computed get tariffsCreateMHExists() {
    return this.data.length > 0;
  }

  @computed get tariffsWithoutIndexes() {
    return this.data.filter(tariff => !tariff.sectors.includes('index'));
  }

  getById = computedFn((id: string) => {
    return this.data.find(tariff => tariff.id === id);
  });

  getTariffByTickers = computedFn(
    (baseTicker, quoteTicker): HodlTariffResponseItem | undefined => {
      return this.data.find(
        tariff =>
          tariff.baseTicker === baseTicker && tariff.quoteTicker === quoteTicker
      );
    }
  );

  getAllTariffsByTickers = computedFn(
    (baseTicker, quoteTicker): HodlTariffResponseItem[] => {
      return this.data.filter(
        tariff =>
          tariff.baseTicker === baseTicker && tariff.quoteTicker === quoteTicker
      );
    }
  );

  getBestRolloverTariffByTickers = computedFn(
    (baseTicker, quoteTicker): HodlTariffResponseItem => {
      const tariffsByTickers = this.getAllTariffsByTickers(
        baseTicker,
        quoteTicker
      );

      return tariffsByTickers.reduce((acc, t) => {
        return t.settlementFee < acc.settlementFee ? t : acc;
      });
    }
  );

  isTariffsExtendMHAvailable = computedFn((baseTicker, quoteTicker) => {
    const tariff = this.getTariffByTickers(baseTicker, quoteTicker);

    if (!tariff) {
      return false;
    }

    return !tariff.extendSlDisabled && tariff.tradingMode === 'ENABLED';
  });
}
