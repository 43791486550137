import {
  formatDistanceToNowStrict,
  parseISO,
  compareAsc,
  addDays,
  format as formatDate,
} from '@youtoken/ui.date-fns';
import upperFirst from 'lodash/upperFirst';

const periodsMap: {[key: string]: string} = {
  '1h': 'hourly',
  '1d': 'daily',
  '10d': 'daily',
  '7d': 'weekly',
  '1w': 'weekly',
  '1m': 'monthly',
};

const periodsDirectMap: {[key: string]: string} = {
  '1h': '1 hour',
  '1d': '1 day',
  '7d': '1 week',
  '1w': '1 week',
  '1m': '1 month',
};

const periodsDirectDigitFreeMapShort: {[key: string]: string} = {
  '1h': 'h',
  '1d': 'd',
  '7d': 'w',
  '1w': 'w',
  '1m': 'm',
};

const periodNameMap: {[key: string]: string} = {
  m: 'minute',
  h: 'hour',
  d: 'day',
  w: 'week',
  B: 'business_day',
  M: 'month',
  Y: 'year',
};

export const periodParser = (period: string | undefined) => {
  return periodsMap[period] ?? period;
};

export const periodParserDirect = (period: string | undefined) => {
  return periodsDirectMap[period] ?? period;
};

export const periodParserDirectDigitFreeShort = (
  period: string | undefined
) => {
  return periodsDirectDigitFreeMapShort[period] ?? period;
};

export const formatDateToDistance = (date: string | Date, addSuffix = true) =>
  formatDistanceToNowStrict(typeof date === 'string' ? parseISO(date) : date, {
    addSuffix,
  });

export const theDateIsLeft = (date: string | Date) => {
  const dateToCompare = typeof date === 'string' ? parseISO(date) : date;
  return compareAsc(dateToCompare, new Date()) === -1;
};

export const addDaysFormatted = (date: string | Date, amount: number) =>
  addDays(typeof date === 'string' ? parseISO(date) : date, amount);

// NOTE: we need only the names of week days, made from number, so use a small trick:
// this year starts from Sunday and this is the first day of week from BE data
// NOTE: will be translated with date-fns
export const formatAndLocalizeWeekDay = (day: number) => {
  return upperFirst(formatDate(new Date(2023, 0, day + 1), 'EEEE'));
};

export const parseDateForLokalise = (period: string): string[] => {
  // 3h, 15m, 10Y etc
  const PERIOD_REGEXP = /(\d+)(\w)/;
  const [, amount, unit] = period.match(PERIOD_REGEXP);

  return [amount, periodNameMap[unit]];
};
