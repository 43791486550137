import {computed, observable} from 'mobx';
import {last} from 'lodash';
import Big from 'big.js';
import {
  alias,
  createSimpleSchema,
  list,
  object,
  optional,
  primitive,
  serializable,
} from 'serializr';
import {Duration, formatDuration} from '@youtoken/ui.date-fns';
import {
  bigNumber,
  bigNumberNullable,
  dateNullable,
  durationNullable,
  number,
} from '@youtoken/ui.utils-serializr';
import {
  formatLevelVolume,
  formatVolumeWithDecimalSeparators,
  getLevelColor,
  getLevelIconName,
  getLevelName,
} from '@youtoken/ui.loyalty-miner-utils';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {
  formatByTicker,
  formatPercent,
  periodParser,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {TColorTokens} from '@youtoken/ui.primitives';
import {LoyaltyIconName} from '@youtoken/ui.icons';
import {LoyaltyResource} from './LoyaltyResource';
import {type LevelStatus} from './types';

export class LoyaltyLevelDetails {
  minerMaxIncomePeriod = '1m';

  minerMaxIncomeTicker = 'btc';

  @computed
  get minerMaxIncomePeriodFormatted() {
    return periodParser(this.minerMaxIncomePeriod);
  }

  @computed
  get minerMaxIncomeTickerFormatted() {
    return this.minerMaxIncomeTicker.toUpperCase();
  }

  @observable
  @serializable(alias('MinerMaxMonthlyIncome', number()))
  minerMaxMonthlyIncome!: number;

  @observable
  @serializable(alias('ConvertRequiredVolume', number()))
  convertRequiredVolume: number;

  @observable
  @serializable(alias('ConvertVolumeMultiplier', number()))
  convertVolumeMultiplier!: number;

  @observable
  @serializable(alias('ConvertVolumeUnit', number()))
  convertVolumeUnit!: number;

  @observable
  @serializable(alias('HodlVolumeMultiplier', number()))
  hodlVolumeMultiplier!: number;

  @observable
  @serializable(alias('HodlVolumeUnit', number()))
  hodlVolumeUnit!: number;

  @computed get minerMaxMonthlyIncomeBTCFormatted() {
    return formatByTicker(
      this.minerMaxMonthlyIncome,
      this.minerMaxIncomeTicker
    );
  }

  @computed get minerMaxMonthlyIncomeUSDFormatted() {
    const {getRate} = RatesResource.__DANGEROUSLY__getInstanceStatically({});

    const rate = getRate('btc', 'usd');
    const value = toBig(this.minerMaxMonthlyIncome).mul(rate);

    return formatByTicker(value, 'usd');
  }
}

export class LoyaltyLevelSaving {
  @serializable(number())
  limit!: number;

  @serializable(primitive())
  period!: string;

  @serializable(number())
  tickerAmount!: number;

  @serializable(
    object(
      createSimpleSchema({
        '*': number(),
      })
    )
  )
  tickers!: Record<string, number>;

  @serializable(primitive())
  shouldShowTariff: boolean;

  @computed
  get tickersKeys() {
    return Object.keys(this.tickers);
  }

  @computed
  get maxTickersAPR() {
    return Math.max(...Object.values(this.tickers));
  }

  @computed
  get assets() {
    return this.tickersKeys.map(ticker => {
      const apr = this.tickers[ticker];

      return {ticker, apr, aprFormatted: formatPercent(apr) + '%'};
    });
  }

  //#regionstart maxIncome

  maxIncomePeriod = '1m';

  maxIncomeTicker = 'usd';

  @computed
  get maxIncomeAmount() {
    return toBig(this.limit).mul(this.maxTickersAPR).div(12);
  }

  @computed
  get maxIncomePeriodFormatted() {
    return periodParser(this.maxIncomePeriod);
  }

  @computed
  get maxIncomeTickerFormatted() {
    return this.maxIncomeTicker.toUpperCase();
  }

  @computed
  get maxIncomeAmountFormatted() {
    return formatByTicker(this.maxIncomeAmount, this.maxIncomeTicker);
  }

  //#endregion maxIncome
}

export class LoyaltyLevelMiner {
  // reward ticker - basically hardcode BTC
  @serializable(primitive())
  @observable
  earnTicker!: string;

  // reward per block
  @serializable(bigNumber())
  @observable
  earnAmount!: Big;

  @computed
  get earnTickerFormatted() {
    return this.earnTicker.toUpperCase();
  }

  @computed
  get earnAmountFormatted() {
    return formatByTicker(this.earnAmount, this.earnTicker);
  }

  // "mining speed" in design
  @serializable(primitive())
  @observable
  miningPeriod!: string;

  // "blocksAvaliable"
  @serializable(alias('miningBlockAmount', primitive()))
  @observable
  blocksAvailable!: number;

  // cost per block;
  @serializable(primitive())
  @observable
  miningCost!: number;

  // not used right now, but should be;
  @serializable(primitive())
  @observable
  freeSparksAmount?: number;

  // "Free spark every";
  @serializable(primitive())
  @observable
  freeSparksPeriod!: string | null; // '24h';

  // "The period after which we stop giving away free sparks";
  @serializable(durationNullable())
  @observable
  freeSparksTerminatePeriod!: Duration | null; // '7d';

  @computed
  get freeSparksTerminatePeriodFormatted() {
    return (
      this.freeSparksTerminatePeriod &&
      formatDuration(this.freeSparksTerminatePeriod)
    );
  }

  // Wellcome pack (number of sparks);
  @serializable(number())
  @observable
  welcomePack!: number;

  /** Get `hodlSparksAmount` for each `hodlSparksRequiredVolume` volume*/
  @serializable(bigNumber())
  @observable
  hodlSparksRequiredVolume!: Big;

  @computed
  get hodlSparksRequiredVolumeFormatted() {
    return formatByTicker(this.hodlSparksRequiredVolume, this.earnTicker);
  }

  /** Get `hodlSparksAmount` for each `hodlSparksRequiredVolume` volume*/
  @serializable(number())
  @observable
  hodlSparksAmount!: number;

  /** requirement to unlock this level */
  @serializable(alias('requiredVolume', number()))
  @observable
  _requiredVolume: number;
}

export class LoyaltyLevelConversion {
  @serializable(primitive())
  @observable
  ticker!: string;

  //#region sparks

  @serializable(number())
  @observable
  sparksAmount!: number;

  @serializable(bigNumber())
  @observable
  sparksRequiredVolume!: Big;

  @computed
  get sparksRequiredVolumeFormatted() {
    return formatByTicker(this.sparksRequiredVolume, this.ticker);
  }

  //#endregion sparks

  //#region tradingVolume

  @serializable(number())
  @observable
  multiplier!: number;

  @serializable(bigNumberNullable())
  @observable
  conversionRequiredVolume!: Big | null;

  @computed
  get conversionRequiredVolumeFormatted(): string | null {
    return (
      this.conversionRequiredVolume &&
      formatByTicker(this.conversionRequiredVolume, this.ticker)
    );
  }

  @computed
  get conversionVolume(): Big | null {
    return this.conversionRequiredVolume?.mul(this.multiplier);
  }

  @computed
  get conversionVolumeFormatted(): string | null {
    return (
      this.conversionVolume &&
      formatByTicker(this.conversionVolume, this.ticker)
    );
  }

  //#endregion tradingVolume
}

export class LoyaltyLevelIncentives {
  @observable
  @serializable(primitive())
  maxLimit!: number; // Maximum value of incentives for account

  @observable
  @serializable(primitive())
  maxPercent!: number; // Maximum percent of accrued incentives on deposit

  @observable
  @serializable(primitive())
  minPercent!: number; // Minimal percent of accrued incentives on deposit

  @observable
  @serializable(primitive())
  minDepositAmount!: number; // Minimal deposit value for getting incentives
}

export class LoyaltyLevel {
  @observable
  @serializable(number())
  level!: number;

  @observable
  @serializable(optional(number()))
  requiredVolume?: number;

  @serializable(number())
  requiredDeposit!: number;

  @computed
  get requiredDepositVisible() {
    // NOTE: lvl2 overwrite backend value
    if (this.level === 2) {
      return 20;
    }

    return this.requiredDeposit;
  }

  @computed
  get requiredDepositVisibleFormatted() {
    if (!this.requiredDepositVisible) {
      return undefined;
    }

    return formatLevelVolume(this.requiredDepositVisible);
  }

  @observable
  @serializable(object(LoyaltyLevelDetails))
  details!: LoyaltyLevelDetails;

  @serializable(object(LoyaltyLevelSaving))
  saving!: LoyaltyLevelSaving;

  @serializable(object(LoyaltyLevelMiner))
  miner!: LoyaltyLevelMiner;

  @serializable(object(LoyaltyLevelConversion))
  conversion!: LoyaltyLevelConversion;

  @serializable(optional(object(LoyaltyLevelIncentives)))
  incentives?: LoyaltyLevelIncentives;

  @observable
  @serializable(primitive())
  hodlSparksAmount: string;

  @observable
  @serializable(primitive())
  hodlSparksRequiredVolume: string;

  @computed get requiredVolumeFormatted() {
    return formatLevelVolume(this.requiredVolume);
  }

  @computed get requiredVolumeFormattedWithSeparators() {
    return formatVolumeWithDecimalSeparators(this.requiredVolume);
  }

  @computed get name() {
    return getLevelName(this.level);
  }

  @computed get color(): keyof TColorTokens {
    return getLevelColor(this.level);
  }

  @computed get iconName(): LoyaltyIconName {
    return getLevelIconName(this.level);
  }

  @computed get status(): LevelStatus {
    const {
      data: {currentLevel},
    } = LoyaltyResource.__DANGEROUSLY__getInstanceStatically({});

    if (this.level < currentLevel) {
      return 'completed';
    }

    if (this.level > currentLevel) {
      return 'locked';
    }

    return 'active';
  }

  @computed get cardIconName(): LoyaltyIconName {
    const {
      data: {currentLevel},
    } = LoyaltyResource.__DANGEROUSLY__getInstanceStatically({});

    const iconName = this.iconName;

    if (this.level >= currentLevel) {
      return iconName;
    }

    return `${iconName}-grayscale` as LoyaltyIconName;
  }

  @computed get cardColor(): keyof TColorTokens {
    const {
      data: {currentLevel},
    } = LoyaltyResource.__DANGEROUSLY__getInstanceStatically({});

    if (this.level >= currentLevel) {
      return this.color;
    }

    return '$ui-01';
  }

  @computed get cardInfoColor() {
    const {
      data: {currentLevel},
    } = LoyaltyResource.__DANGEROUSLY__getInstanceStatically({});

    if (this.level >= currentLevel) {
      return '$ui-background';
    }

    return '$ui-02';
  }

  @computed get cardInfoBadgeVariant() {
    if (this.status === 'completed') {
      return 'locked';
    }

    return 'success';
  }
}

export class LoyaltyResponse {
  @observable
  @serializable(number())
  currentLevel!: number;

  // NOTE: temp, need make on backend
  @computed get nextLevel() {
    return this.levels.find(({level}) => level === this.currentLevel + 1)
      ?.level;
  }

  // NOTE: temp, need make on backend
  @computed get downgradeLevel() {
    // NOTE: If currentLevel below Basic (id = 2), then minDowngradeLevel = Newbie (id = 1), else Basic
    const minDowngradeLevel = Math.min(this.currentLevel, 2);

    // NOTE: If currentVolume = 0 then downgrade to minDowngradeLevel
    if (this.currentVolume === 0) {
      return minDowngradeLevel;
    }

    // NOTE: If currentVolume > 0 then downgrade to previous level
    return (
      last(
        this.levels.filter(level => level.requiredVolume <= this.currentVolume)
      )?.level ?? minDowngradeLevel
    );
  }

  @observable
  @serializable(number())
  currentVolume!: number;

  @computed get currentVolumeFormatted() {
    return formatLevelVolume(this.currentVolume);
  }

  @observable
  @serializable(list(object(LoyaltyLevel)))
  levels!: LoyaltyLevel[];

  @observable
  @serializable(number())
  timeLeftUntilLevelDowngrade!: number;

  @serializable(dateNullable())
  @observable
  incentivesBurningAt: Date | null;

  // '3d', '74h' etc.
  @observable
  @serializable(primitive())
  incentivesExpirationPeriod!: string;
}
