import {computed} from 'mobx';
import big from 'big.js';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {formatBigNumber, formatByTicker} from '@youtoken/ui.formatting-utils';
import {getCoinDecimalPrecision, getCoinSymbol} from '@youtoken/ui.coin-utils';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {formatDistanceToNowStrict, add} from '@youtoken/ui.date-fns';
import {parseDuration} from '@youtoken/ui.utils-serializr';

export class IncentivesFeature extends createFeature({
  getKey: () => `incentivesFeature`,
  getResources: () => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
      wallets: getResourceDescriptor(WalletsResource, {}),
      rates: getResourceDescriptor(RatesResource, {}),
      tariffsMH: getResourceDescriptor(HodlsTariffsResource, {}),
      loyalty: getResourceDescriptor(LoyaltyResource, {}),
    };
  },
}) {
  incentivesTicker = 'usd'; //  NOTE: we can't use yhusd in interfaces

  @computed get incentivesTickerSymbol() {
    return getCoinSymbol(this.incentivesTicker);
  }

  @computed get incentivesForLoyaltyLevel() {
    return this.resources.loyalty.currentLevelData.incentives;
  }

  @computed
  get incentivesEnabled() {
    if (
      !this.resources.authMe.products.incentives ||
      !this.incentivesForLoyaltyLevel
    ) {
      return false;
    }

    const {isEnabled, available} = this.resources.authMe.products.incentives;
    return available && isEnabled;
  }

  @computed
  public get showIncentivesBonuses() {
    return (
      this.incentivesEnabled && this.incentivesForLoyaltyLevel?.maxPercent > 0
    );
  }

  @computed get incentivesBalance() {
    return this.resources.wallets.bonusesWallet?.amount ?? big(0);
  }

  @computed get incentivesBalanceFormatted() {
    return formatByTicker(this.incentivesBalance, this.incentivesTicker);
  }

  @computed get incentivesBalanceIsPositive() {
    return this.incentivesBalance.gt(0);
  }

  @computed get maxPercentOnDeposit() {
    return this.incentivesForLoyaltyLevel?.maxPercent ?? 0;
  }

  @computed get minDepositForGetIncentives() {
    return this.incentivesForLoyaltyLevel?.minDepositAmount ?? 0;
  }

  @computed get incentivesLimit() {
    return this.incentivesForLoyaltyLevel?.maxLimit ?? 0;
  }

  @computed get exampleDepositValue() {
    const exampleValue =
      this.incentivesLimit * (100 / this.maxPercentOnDeposit);

    return isFinite(exampleValue) && exampleValue > 0
      ? formatBigNumber(exampleValue, getCoinDecimalPrecision('usd'), true)
      : '1000';
  }

  @computed get exampleIncentivesValue() {
    const exampleValue =
      (Number(this.exampleDepositValue) * this.maxPercentOnDeposit) / 100;

    return formatBigNumber(
      exampleValue,
      getCoinDecimalPrecision('bonus'),
      true
    );
  }

  @computed get incentivesBurningIn() {
    return this.resources.loyalty.data.incentivesBurningAt
      ? formatDistanceToNowStrict(
          this.resources.loyalty.data.incentivesBurningAt,
          {
            addSuffix: true,
          }
        )
      : undefined;
  }

  @computed get incentivesBurningInNoPrefix() {
    return this.resources.loyalty.data.incentivesBurningAt
      ? formatDistanceToNowStrict(
          this.resources.loyalty.data.incentivesBurningAt,
          {
            addSuffix: false,
          }
        )
      : undefined;
  }

  @computed get incentivesExpirationPeriod() {
    return this.resources.loyalty.data.incentivesExpirationPeriod;
  }

  @computed get incentivesExpirationPeriodFormatted() {
    const period = this.resources.loyalty.data.incentivesExpirationPeriod;

    if (!period) {
      return '';
    }

    const duration = parseDuration(period);
    const now = new Date();
    const future = add(now, duration);

    return formatDistanceToNowStrict(future, {addSuffix: false});
  }
}
