import * as React from 'react';
import type {WizardStep} from '../types';
import {WizardContext} from '../context';
import {useWizard} from '../hooks';

export const createWizard = <Wizard extends Object>() => {
  const Navigator = <WizardScreen extends keyof Wizard = keyof Wizard>({
    initialName,
    initialProps,
    goBack: goBackParent,
    children,
  }: React.PropsWithChildren<{
    initialName: WizardScreen;
    initialProps?: Wizard[WizardScreen];
    goBack?: () => void;
  }>) => {
    const [state, setState] = React.useState([
      {
        name: initialName,
        props: initialProps,
      },
    ] as Array<WizardStep<Wizard, WizardScreen>>);

    const step = React.useMemo(() => {
      const {name, props} = state[state.length - 1];

      return {
        name,
        props: (props ?? {}) as Wizard[keyof Wizard],
      };
    }, [state]);

    const navigate = React.useCallback(
      (name: WizardScreen, props: Wizard[WizardScreen]) => {
        setState(state => {
          return state.concat({
            name,
            props,
          });
        });
      },
      []
    );

    const goBackDefault = React.useCallback(
      (overrideProps: Wizard[WizardScreen]) => {
        setState(state => {
          const prevState = state.slice(0, -1);

          if (overrideProps) {
            prevState[prevState.length - 1].props = {
              ...overrideProps,
            };
          }

          return prevState;
        });
      },
      []
    );

    const goBack = state.length > 1 ? goBackDefault : goBackParent;

    const {step: parentStep, close = goBackParent} = useWizard();

    const canGoBack = Boolean(parentStep || state.length > 1);

    const value = React.useMemo(() => {
      return {
        canGoBack,
        step,
        navigate,
        goBack: canGoBack ? goBack : undefined,
        close,
      };
    }, [canGoBack, step, navigate, goBack, close]);

    return (
      <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
    );
  };

  const Screen = <WizardScreen extends keyof Wizard = keyof Wizard>({
    name,
    component: Component,
  }: {
    name: WizardScreen;
    component: React.ComponentType;
    options?: any;
  }) => {
    const {
      step: {name: currentName},
    } = useWizard<Wizard, WizardScreen>();

    if (currentName === name) {
      return <Component />;
    }

    return null;
  };

  return {
    Navigator,
    Screen,
  };
};
