import {computed, observable} from 'mobx';
import {
  alias,
  list,
  object,
  optional,
  primitive,
  serializable,
} from 'serializr';
import Big from 'big.js';
import {bigNumberNullable, number} from '@youtoken/ui.utils-serializr';
import {getCoinName} from '@youtoken/ui.coin-utils';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {formatDaysOff} from './utils';
import {Category} from './types';

export enum TradingMode {
  ENABLED = 'ENABLED',
  CLOSE_ONLY = 'CLOSE_ONLY',
  DISABLED = 'DISABLED', // NOTE: actually such tariffs are filtered on the BE side
}

export class TradingHoursResponse {
  @serializable(number())
  @observable
  dayFrom!: number;

  @serializable(primitive())
  @observable
  timeFrom!: string;

  @serializable(number())
  @observable
  dayTo!: number;

  @serializable(primitive())
  @observable
  timeTo!: string;
}

export class HodlTariffResponseItem {
  @serializable(primitive())
  @observable
  settlementFee: number = 0;

  @serializable(list(primitive()))
  @observable
  allowedInputTickers: string[] = [];

  @computed
  get id() {
    return `${this.baseTicker}_${this.quoteTicker}`;
  }

  @serializable(alias('id', primitive()))
  @observable
  _id!: string;

  @serializable(primitive())
  isShort!: boolean;

  @serializable(primitive())
  baseTicker!: string;

  @serializable(primitive())
  quoteTicker!: string;

  // @serializable(primitive())
  // decimals!: number;

  @serializable(alias('decimals', primitive()))
  precision!: number;

  @serializable(primitive())
  defaultMultiplier!: number;

  @serializable(primitive())
  minMultiplier!: number;

  @serializable(primitive())
  maxMultiplier!: number;

  @serializable(primitive())
  minVolume!: number;

  @serializable(primitive())
  maxVolume!: number;

  @serializable(primitive())
  apr!: number;

  @serializable(primitive())
  openingFee!: number;

  @serializable(primitive())
  mcFee!: number;

  @serializable(primitive())
  settlementPeriod!: string;

  @serializable(primitive())
  revShareFee!: number;

  @serializable(primitive())
  unifiedFee!: number;

  // for pending orders
  @serializable(optional(number()))
  @observable
  expirationPeriod?: number;

  // for hodls
  @serializable(optional(number()))
  @observable
  hodlExpirationPeriod?: number;

  @serializable(number())
  triggerPriceDistanceMax?: number;

  @serializable(primitive())
  pendingOrderDisabled!: boolean;

  @serializable(alias('dayOff', primitive()))
  isDayOff!: boolean;

  @serializable(primitive())
  extendSlDisabled!: boolean;

  @serializable(optional(list(primitive())))
  sectors?: string[];

  @serializable(optional(list(object(TradingHoursResponse))))
  @observable
  daysOff?: TradingHoursResponse[];

  @serializable(primitive())
  @observable
  tradingMode!: TradingMode;

  @serializable(list(primitive()))
  @observable
  categories!: Category[];

  @serializable(primitive())
  @observable
  bonusesEnabled!: boolean;

  @serializable(optional(number()))
  @observable
  maxBonusesPercent?: number;

  @serializable(bigNumberNullable())
  @observable
  maxBonusesAmount: Big | null = null;

  @computed
  get baseTickerFormatted() {
    return this.baseTicker.toUpperCase();
  }

  @computed
  get baseTickerName() {
    return getCoinName(this.baseTicker);
  }

  @computed
  get quoteTickerFormatted() {
    return this.quoteTicker.toUpperCase();
  }

  @computed
  get isStarDisabled() {
    return false;
  }

  @computed
  get isStarred() {
    const {starred} = AuthMeResource.__DANGEROUSLY__getInstanceStatically({});

    return starred.includes(this.id);
  }

  @computed
  get itIsIndex() {
    return Boolean(this.sectors?.includes('index'));
  }

  @computed
  get daysOffFormatted() {
    return formatDaysOff(this.daysOff);
  }

  @computed
  get isCategoryNew() {
    return this.categories.includes(Category.NEW);
  }

  @computed
  get isCategoryPopular() {
    return this.categories.includes(Category.POPULAR);
  }

  @computed
  get isCategoryRollovers() {
    return this.categories.includes(Category.ROLLOVERS);
  }

  @computed get rate(): number {
    const {getRate} = RatesResource.__DANGEROUSLY__getInstanceStatically({
      product: 'hodl',
    });
    return getRate(this.baseTicker, this.quoteTicker);
  }
  @computed get diff24Percent(): number {
    const {getDiff24, getRate} =
      RatesResource.__DANGEROUSLY__getInstanceStatically({
        product: 'hodl',
      });
    const rate = getRate(this.baseTicker, this.quoteTicker);
    const diff = getDiff24(this.baseTicker, this.quoteTicker);
    const rate24hAgo = rate - diff;
    return (diff / rate24hAgo) * 100;
  }
}

export class HodlTariffResponse {
  @serializable(list(object(HodlTariffResponseItem)))
  @observable
  data!: HodlTariffResponseItem[];
}
