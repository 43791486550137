import {AppState, type AppStateStatus, Platform} from 'react-native';
import {
  _allowStateChangesInsideComputed,
  action,
  computed,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
  transaction,
} from 'mobx';
import {computedFn} from 'mobx-utils';
import {createSimpleSchema, deserialize, object} from 'serializr';
import Big from 'big.js';
import {debounce, without} from 'lodash';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {RateObj, RatesResponse} from './RatesResponse';

/** returns throttle wait time for give amount of subs
 *
 * main reason for this is to avoid too many updates on frontend;
 * in case with have very few subs, we want to update rates as fast as possible.
 * in case with have many subs, we want to update rates once in 5 seconds or so.
 *
 * for now it's the same value as it was before, but after we will check it live on device
 * we will be able to calculate optimal wait time for given platform/number of subs;
 *
 *
 * @example
 * 2 subs => wait time = 1 seconds
 * 50 subs => wait time = 5 seconds
 * 100 subs => wait time = 10 second
 */
const getRatesThrottleWaitTime = (
  _numberOfRatesToSubscribe: number
): number => {
  return Platform.select({native: 1000, web: 1000});
};

type SocketRate = {
  fromTicker: string;
  toTicker: string;
  price: number;
  bid: number;
  ask: number;
  diff: number;
  diff24h: number;
};

const getKeyPair = (from: string, to: string) => {
  return `${from}/${to}`;
};

const RatesSchema = createSimpleSchema<{[key: string]: RateObj}>({
  '*': object(RateObj),
});

export type RatesResourceArgs = {
  product?: 'hodl' | 'convert' | 'default';
};

export class RatesResource extends createResource<
  RatesResourceArgs,
  RatesResponse
>({
  getKey: ({product = 'default'}) => `RatesResource:${product}`,
  getData: ({product = 'default'}) =>
    TRANSPORT.API.get(`/v3/rates/extended?product=${product}`).then(
      (res: any) => {
        const rawData = res.data;
        const tmp = Object.keys(rawData).reduce((acc, fromTicker) => {
          return Object.keys(rawData[fromTicker]).reduce((acc, toTicker) => {
            acc[getKeyPair(fromTicker, toTicker)] =
              rawData[fromTicker][toTicker];
            return acc;
          }, acc);
        }, {} as RatesResponse);

        return deserialize(RatesSchema, tmp);
      }
    ),
  shouldBeDeletedOnCleanup: false,
}) {
  @observable subs = [];

  @observable appState: AppStateStatus = AppState.currentState;

  constructor(args: RatesResourceArgs, data: RatesResponse) {
    super(args, data);

    TRANSPORT.SOCKET.on(this.eventName, this.reactOnSocketMessage);
    TRANSPORT.SOCKET.on('disconnect', () => {});
    TRANSPORT.SOCKET.on('connect', () => {
      this.subscribeRates(this.subs);
    });

    Object.keys(data).map(key => {
      this.addPairToSubs(key);
    });

    reaction(() => this.subs, this.reactOnSubsChanged, {
      fireImmediately: true,
    });

    AppState.addEventListener('change', state => {
      this.appState = state;
    });
  }

  onDestroy() {
    super.onDestroy();

    TRANSPORT.SOCKET.off(this.eventName, this.reactOnSocketMessage);
    TRANSPORT.SOCKET.off('connect', () => this.subscribeRates(this.subs));
  }

  @action assignData = (data: any) => {
    if (this.appState === 'active') {
      Object.keys(this.data).forEach(key => {
        Object.keys(this.data[key]).forEach(_key => {
          if (data[key]?.[_key]) {
            this.data[key][_key] = data[key][_key];
          }
        });
      });
    }
  };

  @action addPairToSubs = (pair: string) => {
    onBecomeObserved(this.data, pair, () => {
      _allowStateChangesInsideComputed(() => {
        if (!this.subs.includes(pair)) {
          runInAction(() => {
            this.subs = [...this.subs, pair];
          });
        }
      });
    });

    onBecomeUnobserved(this.data, pair, () => {
      _allowStateChangesInsideComputed(() => {
        if (this.subs.includes(pair)) {
          runInAction(() => {
            this.subs = without(this.subs, pair);
          });
        }
      });
    });
  };

  @computed get subscriptionArgs() {
    switch (this.args.product) {
      case 'hodl':
        return {name: 'product-rates', product: 'hodl'};
      case 'convert':
        return {name: 'product-rates', product: 'convert'};
      default:
        return {name: 'rates-selected'};
    }
  }

  @computed get eventName() {
    return ['hodl', 'convert'].includes(this.args.product)
      ? 'product-rates'
      : 'rates-selected';
  }

  subscribeRates = (subs: string[]) => {
    TRANSPORT.SOCKET.emit('sub', {
      ...this.subscriptionArgs,
      symbols: subs,
      throttle: getRatesThrottleWaitTime(subs.length),
    });
  };

  @action reactOnSubsChanged = debounce(this.subscribeRates, 1000);

  @action reactOnSocketMessage = (data: SocketRate[]) => {
    if (this.appState !== 'active') {
      return;
    }

    transaction(() => {
      data.forEach(rate => {
        const key = getKeyPair(rate.fromTicker, rate.toTicker);

        if (this.data[key]) {
          this.data[key].rate = rate.price;
          this.data[key].bid = rate.bid;
          this.data[key].ask = rate.ask;
          this.data[key].diff = rate.diff;
          this.data[key].diff24h = rate.diff24h;
        }
      });
    });
  };

  private fallbackRate = {rate: 1, diff: 0, diff24h: 0, ask: 1, bid: 1};

  getRateObj = computedFn(
    (from: string | undefined, to: string | undefined) => {
      if (!from || !to) {
        return this.fallbackRate;
      }

      return this.data[getKeyPair(from, to)] ?? this.fallbackRate;
    }
  );

  getRate = computedFn((from: string, to: string) => {
    return this.getRateObj(from, to).rate;
  });

  getExchangeRate = computedFn((from: string, to: string) => {
    return this.getRateObj(from, to).bid;
  });

  getDiff = computedFn((from: string, to: string) => {
    return this.getRateObj(from, to).diff;
  });

  getDiff24 = computedFn((from: string, to: string) => {
    return this.getRateObj(from, to).diff24h;
  });

  /** returns rate as string, with default precision
   *
   * @example
   * getRateAsString('btc', 'usd') => '12345.67'
   */
  getRateStringFormatted = computedFn((from: string, to: string): string => {
    return Big(this.getRate(from, to)).toFixed(getCoinDecimalPrecision(to));
  });

  /** returns diff percent related to actual price as Big number
   *
   * @example
   * getDiffPercent('btc', 'usd') => Big(123)
   */
  getDiffPercent = computedFn((from: string, to: string): Big => {
    const rate = Big(this.getRate(from, to));
    const diffInRate = Big(this.getDiff24(from, to));

    return diffInRate.div(rate).times(100);
  });

  /** returns diff percent related to price 24h ago as Big number
   *
   * @example
   * getDiffPercent('btc', 'usd') => Big(123)
   */
  getDiff24Percent = computedFn((from: string, to: string): Big => {
    const rate = Big(this.getRate(from, to));
    const diffInRate = Big(this.getDiff24(from, to));

    const rate24hAgo = rate.minus(diffInRate);

    return diffInRate.div(rate24hAgo);
  });

  /** returns diff percent as string, with default precision
   *
   * @example
   * getDiffPercentString('btc', 'usd') => "23.45%
   *
   */
  getDiffPercentFormatted = computedFn((from: string, to: string): string => {
    return `${this.getDiffPercent(from, to).abs().toFixed(2)}%`;
  });

  /** returns diff direction - up or down
   * @example
   * getDiffDirection('btc', 'usd') => 'up'
   */
  getDiffDirection = computedFn((from: string, to: string): 'up' | 'down' => {
    return this.getDiffPercent(from, to).gte(0) ? 'up' : 'down';
  });
}
