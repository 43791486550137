import * as React from 'react';
import {
  type WizardClose,
  type WizardGoBack,
  type WizardNavigate,
  type WizardStep,
} from './types';

export type WizardContextValue<
  Wizard extends Object,
  WizardScreen extends keyof Wizard
> = {
  step: WizardStep<Wizard, WizardScreen>;
  navigate: WizardNavigate<Wizard>;
  canGoBack: boolean;
  goBack?: WizardGoBack<Wizard>;
  close: WizardClose;
};

export const WizardContext = React.createContext({} as any);
