import {computed, observable} from 'mobx';
import {
  serializable,
  primitive,
  optional,
  object,
  list,
  alias,
} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';
import {getCoinName} from '@youtoken/ui.coin-utils';
import {RatesResource} from '@youtoken/ui.resource-rates';

export class HODLPublicInstrumentItem {
  @serializable(alias('decimals', number()))
  @observable
  precision!: number;

  @serializable(primitive())
  @observable
  quoteTicker!: string;

  @serializable(primitive())
  @observable
  baseTicker!: string;

  @serializable(alias('id', primitive()))
  @observable
  _id!: string;

  @serializable(optional(list(primitive())))
  @observable
  sectors?: string[];

  @computed
  get id() {
    return `${this.baseTicker}_${this.quoteTicker}`;
  }

  @computed
  get baseTickerFormatted() {
    return this.baseTicker.toUpperCase();
  }

  @computed
  get baseTickerName() {
    return getCoinName(this.baseTicker);
  }

  @computed
  get quoteTickerFormatted() {
    return this.quoteTicker.toUpperCase();
  }

  @computed
  get isStarDisabled() {
    return true;
  }

  @computed
  get isStarred() {
    return false;
  }

  @computed
  get itIsIndex() {
    return Boolean(this.sectors?.includes('index'));
  }

  @computed
  get isCategoryNew() {
    return false;
  }

  @computed
  get isCategoryPopular() {
    return false;
  }

  @computed
  get isCategoryRollovers() {
    return false;
  }

  @computed get rate(): number {
    const {getRate} = RatesResource.__DANGEROUSLY__getInstanceStatically({
      product: 'hodl',
    });
    return getRate(this.baseTicker, this.quoteTicker);
  }

  @computed get diff24Percent(): number {
    const {getDiff24, getRate} =
      RatesResource.__DANGEROUSLY__getInstanceStatically({product: 'hodl'});

    const rate = getRate(this.baseTicker, this.quoteTicker);
    const diff = getDiff24(this.baseTicker, this.quoteTicker);

    const rate24hAgo = rate - diff;

    return (diff / rate24hAgo) * 100;
  }
}

export class HODLPublicInstrumentResponse {
  @observable
  @serializable(list(object(HODLPublicInstrumentItem)))
  data: HODLPublicInstrumentItem[] = [];
}
