import React from 'react';
import {useValidationMessage} from './useValidationMessage';
import {ValidationMessageLocalized} from './ValidationMessageLocalized';

export interface ValidationMessageComponentProps {
  error: string | ValidationMessageLocalized;
}

export const ValidationMessageComponent: React.FC<
  ValidationMessageComponentProps
> = ({error}) => {
  const localizedError = useValidationMessage(error);

  return <span>{localizedError}</span>;
};
