import * as React from 'react';
import {LayoutChangeEvent} from 'react-native';
import {Box} from '@youtoken/ui.primitives';
import {type ExpandedAreaProps} from '../types';

export function ExpandedArea<T>({
  items,
  renderItem,
  onResize,
  wrapperPadding = 16,
  wrapperMargin = 12,
}: ExpandedAreaProps<T>) {
  const scrollableAreaRef = React.useRef<HTMLDivElement>(null);
  const [wrapperWidth, setWrapperWidth] = React.useState(null);
  const oneElementSeparatorValue = wrapperMargin / 2;
  const paddingWithoutSeparatorPart = wrapperPadding - oneElementSeparatorValue;

  React.useEffect(() => {
    if (!onResize) return;

    let contentWidth = 0;

    if (
      wrapperWidth &&
      scrollableAreaRef.current.childNodes &&
      scrollableAreaRef.current.childNodes.length
    ) {
      scrollableAreaRef.current.childNodes?.forEach((el: HTMLDivElement) => {
        contentWidth += +el.clientWidth + wrapperMargin;
      });
    }

    const usableWrapperArea = wrapperWidth - paddingWithoutSeparatorPart * 2;

    onResize(usableWrapperArea > contentWidth);
  }, [wrapperWidth, paddingWithoutSeparatorPart, wrapperMargin, onResize]);

  const handleWrapperLayout = React.useCallback(
    ({nativeEvent: {layout}}: LayoutChangeEvent) => {
      setWrapperWidth(layout.width);
    },
    []
  );

  const renderItemTemplate = React.useCallback(
    (item: T, index: number) => {
      return (
        <Box my={oneElementSeparatorValue} key={index}>
          {renderItem({item, index, isParentListExpanded: true})}
        </Box>
      );
    },
    [oneElementSeparatorValue]
  );

  return (
    <Box
      ref={onResize ? scrollableAreaRef : null}
      onLayout={onResize ? handleWrapperLayout : null}
      p={paddingWithoutSeparatorPart}
      flexWrap="wrap"
      backgroundColor="$ui-01"
      flexDirection="row"
      borderRadius={16}
    >
      {items.map(renderItemTemplate)}
    </Box>
  );
}
