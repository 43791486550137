import {warning} from '@youtoken/ui.utils';
import {priceFormatterInThousands} from '@youtoken/ui.formatting-utils';
import {type TColorTokens} from '@youtoken/ui.primitives';
import {type LoyaltyIconName} from '@youtoken/ui.icons';

const WARNING_PREFIX = 'Loyalty/Miner';

export const levelName: {[key: number]: string} = {
  1: 'Newbie',
  2: 'Basic',
  3: 'Jumpstart',
  4: 'Silver',
  5: 'Gold',
  6: 'Platinum',
  7: 'Diamond',
  8: 'VIP',
};

export const levelColor: {[key: number]: keyof TColorTokens} = {
  1: '$loyalty-level-01',
  2: '$loyalty-level-02',
  3: '$loyalty-level-03',
  4: '$loyalty-level-04',
  5: '$loyalty-level-05',
  6: '$loyalty-level-06',
  7: '$loyalty-level-07',
  8: '$loyalty-level-08',
};

export const levelIconName: {[key: number]: LoyaltyIconName} = {
  1: 'level-1',
  2: 'level-2',
  3: 'level-3',
  4: 'level-4',
  5: 'level-5',
  6: 'level-6',
  7: 'level-7',
  8: 'level-8',
};

export const getLevelName = (level: number) => {
  const name = levelName[level];

  warning(name, `${WARNING_PREFIX}: getLevelName: name by level not found`, {
    level,
  });

  return name;
};

export const getLevelIconName = (level: number) => {
  const iconName = levelIconName[level];

  warning(
    iconName,
    `${WARNING_PREFIX}: getLevelIconName: iconName by level not found`,
    {
      level,
    }
  );

  return iconName;
};

export const getLevelColor = (level: number): keyof TColorTokens => {
  const color = levelColor[level];

  warning(color, `${WARNING_PREFIX}: getLevelColor: color by level not found`, {
    level,
  });

  return color;
};

export const formatLevelVolume = (volume: number) => {
  return `$${priceFormatterInThousands(volume)}`;
};
