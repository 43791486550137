import {i18n} from '@youtoken/ui.service-i18n';

import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';

export const getLocaleByLang = () => {
  const lang = i18n.language;

  return (
    {
      en,
      fr,
      it,
      ru,
      es,
      de,
    }[lang] ?? en
  );
};
