import {warning} from '@youtoken/ui.utils';

export const coinDecimalPrecisions: {[key: string]: number} = {
  yusd: 2,
  usd: 2,
  eur: 2,
  gbp: 2,
  chf: 2,
  usdt: 2,
  usdc: 2,
  husd: 2,
  pax: 2,
  tusd: 2,
  busd: 2,
  dai: 2,
  eurs: 2,
  rub: 2,
  usdp: 2,
  pyusd: 2,
  ars: 2,
  bonus: 2,
};

export const rateQuoteTickersPrecisions: {[key: string]: number} = {
  btc: 6,
  eth: 6,
};

export const getCoinDecimalPrecision = (
  ticker: string,
  defaultPrecision: number = 6
): number => {
  warning(ticker, `no ticker passed, default precision will be used`, {
    ticker,
    defaultPrecision,
  });

  if (!ticker) {
    return defaultPrecision;
  }

  const _ticker = ticker.replace('_', '');

  return coinDecimalPrecisions[_ticker] ?? defaultPrecision;
};

/** sugar for loans, dont use it anywhere else */
export const getCoinDecimalPrecisionForLoans = (ticker: string) => {
  return getCoinDecimalPrecision(ticker, 8);
};

export const getRateDecimalPrecision = (quoteTicker: string) => {
  const _quoteTicker = quoteTicker.replace('_', '');

  return rateQuoteTickersPrecisions[_quoteTicker] ?? 4;
};

/** @deprecated use getCoinDecimalPrecision */
export const getCurrencyPrecision = getCoinDecimalPrecision;
