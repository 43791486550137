import {action, comparer, computed, observable} from 'mobx';
import {debounce, findIndex, orderBy, some, uniqBy} from 'lodash';
import {computedFn} from 'mobx-utils';
import Big from 'big.js';
import {
  formatBigNumber,
  formatPercent,
  priceFormatterWithEllipsis,
} from '@youtoken/ui.formatting-utils';
import {
  type HODLPublicInstrumentItem,
  HODLPublicInstrumentResource,
} from '@youtoken/ui.resource-hodl-public-instruments';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {type SortingTabItem} from '@youtoken/ui.sorting-tabs';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {i18n} from '@youtoken/ui.service-i18n';
import {GLOBAL} from '@youtoken/ui.service-global';
import {invariant} from '@youtoken/ui.utils';
import {type HodlTariffResponseItem} from './HodlsTariffsResponse';
import {HodlsTariffsResource} from './HodlsTariffsResource';
import {Category} from './types';

export class HODLsTariffsFeature extends createFeature({
  getKey: () => `HODLsTariffs`,
  getResources: () => {
    return {
      tariffs: GLOBAL.isAuthorized
        ? getResourceDescriptor(HodlsTariffsResource, {})
        : getResourceDescriptor(HODLPublicInstrumentResource, {}),
      rates: getResourceDescriptor(RatesResource, {product: 'hodl'}),
      ...(GLOBAL.isAuthorized // NOTE: authme resource shouldn't be used in unauthorised mode
        ? {authme: getResourceDescriptor(AuthMeResource, {})}
        : {}),
    };
  },
}) {
  @computed.struct
  public get all(): HodlTariffResponseItem[] | HODLPublicInstrumentItem[] {
    return this.resources.tariffs.data;
  }

  @computed
  public get starred() {
    // filter wrong saved tickers
    return this.resources.authme?.starred.filter(i => i.includes('_')) ?? [];
  }

  // for instruments we use diff 24
  getDiffValue = computedFn((baseTicker: string, quoteTicker: string) => {
    const diffInRate = this.resources.rates.getDiff24(baseTicker, quoteTicker);
    const prefix = diffInRate > 0 ? '+' : '';
    const precisionVal = getCoinDecimalPrecision(quoteTicker);

    return `${prefix} ${formatBigNumber(diffInRate, precisionVal)}`;
  });

  getDiffPercentFormatted = computedFn((diffPercent: Big) => {
    const valueFormatted = formatPercent(diffPercent.abs(), true);
    const sign = diffPercent.gte(0) ? '+' : '-';

    return `${sign}${valueFormatted}%`;
  });

  getDiffDirection = computedFn(
    (baseTicker: string, quoteTicker: string): 'up' | 'down' => {
      const diffPercent = this.resources.rates.getDiff24Percent(
        baseTicker,
        quoteTicker
      );

      return diffPercent.gte(0) ? 'up' : 'down';
    }
  );

  getFormattedRate = computedFn((baseTicker: string, quoteTicker: string) => {
    const {
      rates: {getRate},
      tariffs: {getTariffByTickers},
    } = this.resources;

    const rate = getRate(baseTicker, quoteTicker);

    const precision =
      getTariffByTickers(baseTicker, quoteTicker)?.precision ?? 4;

    return formatBigNumber(rate, precision);
  });

  getDiffAndFormattedRate = computedFn(
    (baseTicker: string, quoteTicker: string) => {
      const diffPercent = this.resources.rates.getDiff24Percent(
        baseTicker,
        quoteTicker
      );
      const rateFormatted = this.getFormattedRate(baseTicker, quoteTicker);

      return {
        diffValue: this.getDiffValue(baseTicker, quoteTicker),
        diffPercent: diffPercent,
        diffPercentFormatted: this.getDiffPercentFormatted(diffPercent),
        diffDirection: this.getDiffDirection(baseTicker, quoteTicker),
        rateFormatted,
        rateWithEllipsisFormatted: priceFormatterWithEllipsis(rateFormatted),
      };
    },
    {equals: comparer.shallow}
  );

  @computed.struct get instruments():
    | HodlTariffResponseItem[]
    | HODLPublicInstrumentItem[] {
    // NOTE: need refactor it on backend! 2 tariffs to one id (short and long)
    return uniqBy(this.all, 'id');
  }

  // #region scrollables
  @computed.struct get starredInstruments() {
    return this.starred
      .filter(item => some(this.instruments, {id: item})) // DEV-887 check that saved tariff exists
      .map(this.resources.tariffs.getById);
  }

  @computed get starredInstrumentsOrder() {
    return this.starredInstruments.map((_, index: number) => index);
  }

  // NOTE: 10 or less instruments required
  @computed.struct get instrumentsTopMovers() {
    return orderBy(
      this.instruments,
      item =>
        this.resources.rates
          .getDiff24Percent(item.baseTicker, item.quoteTicker)
          .abs()
          .toFixed(),
      'desc'
    ).slice(0, 10);
  }

  @computed.struct get instrumentsBestRolloverRates() {
    return orderBy(
      this.instruments.filter(
        i => i.isCategoryRollovers
      ) as HodlTariffResponseItem[],
      item => item.settlementFee,
      'asc'
    ); // best rollover first
  }

  @computed get instrumentsByCategory(): {
    [key in Category]: HodlTariffResponseItem[];
  } {
    return {
      [Category.NEW]: this.instruments.filter(
        i => i.isCategoryNew
      ) as HodlTariffResponseItem[],

      [Category.POPULAR]: this.instruments.filter(
        i => i.isCategoryPopular
      ) as HodlTariffResponseItem[],

      [Category.ROLLOVERS]: this.instrumentsBestRolloverRates,
    };
  }

  // #endregion scrollables

  //#region filters and sort logic
  @observable
  public starredFilterIsActive = false;

  @observable
  public activeCategoryFilter: Category | undefined = undefined;

  @observable
  searchInputFilter: string = '';

  @action setInputVal = debounce((value: string) => {
    try {
      this.searchInputFilter = value.toLowerCase();
      this.resetFilters();
    } catch (e) {
      this.searchInputFilter = '';
    }
  }, 150);

  isFiltersMatched = computedFn(
    (item: HodlTariffResponseItem | HODLPublicInstrumentItem) => {
      return (
        (!this.starredFilterIsActive || item.isStarred) &&
        (!this.activeCategoryFilter ||
          ('categories' in item &&
            item.categories.includes(this.activeCategoryFilter)))
      );
    }
  );

  isSearchMatched = computedFn(
    (item: HodlTariffResponseItem | HODLPublicInstrumentItem) => {
      return (
        item.baseTicker.includes(this.searchInputFilter) ||
        item.quoteTicker.includes(this.searchInputFilter) ||
        item.baseTickerName.toLowerCase().includes(this.searchInputFilter)
      );
    }
  );

  @computed get instrumentsFiltered() {
    return this.instruments.filter(
      item => this.isFiltersMatched(item) && this.isSearchMatched(item)
    );
  }

  @observable public sortingTabs: SortingTabItem[] = [
    {
      value: 'name',
      label: i18n.t('surface.hodls.instruments.sorter.name'),
      direction: 'asc',
      testID: 'ALL_INSTRUMENTS_NAME_SORTING_BUTTON',
    },
    {
      value: 'price',
      label: i18n.t('surface.hodls.instruments.sorter.price'),
      direction: 'desc',
      testID: 'ALL_INSTRUMENTS_PRICE_SORTING_BUTTON',
    },
    {
      value: 'dif24',
      label: i18n.t('surface.hodls.instruments.sorter.percent_change'),
      direction: 'desc',
      testID: 'ALL_INSTRUMENTS_DIF24_SORTING_BUTTON',
    },
  ];

  @observable public activeSortingTabValue = 'dif24';

  @computed get activeSortingTab() {
    return this.sortingTabs.find(
      tab => tab.value === this.activeSortingTabValue
    );
  }

  @computed get activeSortingTabDirection() {
    return this.activeSortingTab.direction;
  }

  @action updateSorting = (item: SortingTabItem) => {
    const index = findIndex(this.sortingTabs, {value: item.value});

    this.sortingTabs[index].direction = item.direction;
    this.activeSortingTabValue = item.value;
  };

  // final result for render
  @computed({equals: comparer.shallow})
  get instrumentsSorted() {
    return orderBy(
      this.instrumentsFiltered,
      item => {
        if (this.activeSortingTabValue === 'name') {
          return item.baseTicker;
        }

        if (this.activeSortingTabValue === 'price') {
          return Number(item.rate);
        }

        if (this.activeSortingTabValue === 'dif24') {
          return item.diff24Percent;
        }
      },
      this.activeSortingTabDirection
    );
  }

  //#endregion filters and sort logic

  @computed({equals: comparer.shallow})
  get tickersPairsList() {
    return this.instruments.map(
      instrument => `${instrument.baseTicker}/${instrument.quoteTicker}`
    );
  }

  getAvailableInstrumentTariffs = computedFn(
    (baseTicker: string, quoteTicker: string) => {
      if (!GLOBAL.isAuthorized) {
        return {
          sellTariffAvailable: true,
          buyTariffAvailable: true,
        };
      }

      const sellTariffAvailable = this.all.find(
        (item: HodlTariffResponseItem) => {
          return (
            item.isShort &&
            item.baseTicker === baseTicker &&
            item.quoteTicker === quoteTicker
          );
        }
      );
      const buyTariffAvailable = this.all.find(
        (item: HodlTariffResponseItem) => {
          return (
            !item.isShort &&
            item.baseTicker === baseTicker &&
            item.quoteTicker === quoteTicker
          );
        }
      );
      return {
        sellTariffAvailable,
        buyTariffAvailable,
      };
    }
  );

  getInstrumentById = computedFn(
    (id: string) => {
      const instrument = this.instruments.find(i => i.id === id);

      invariant(
        instrument,
        `Instrument not found in tariffs`,
        {instrumentId: JSON.stringify(id)}, // (?) this does not show in sentry
        {tariffs: this.all} // (?) this does not show in sentry
      );

      return instrument;
    },
    {equals: comparer.shallow}
  );

  @action toggleStarredHODLsInstrument = item => {
    if (this.starred.includes(item.id)) {
      this.resources.authme?.removeStarredInstrument(item.id);
    } else {
      this.resources.authme?.addStarredInstrument(item.id);
    }
  };

  @action resetStarredHODLsInstruments = () => {
    this.resources.authme?.resetStarredInstruments();
  };

  @action setStarredFilter = (val: boolean) => {
    this.resetFilters();
    this.starredFilterIsActive = val;
  };

  @action setCategoryFilter = (filter: Category) => {
    this.resetFilters();
    this.activeCategoryFilter = filter;
  };

  @action toggleCategoryFilter = (filter: Category) => {
    if (this.activeCategoryFilter === filter) {
      this.resetFilters();
    } else {
      this.setCategoryFilter(filter);
    }
  };

  @action resetFilters = () => {
    this.starredFilterIsActive = false;
    this.activeCategoryFilter = undefined;
  };
}
