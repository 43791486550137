import * as React from 'react';

export const useCountdown = (
  timeToCount: number = 60 * 1000,
  interval: number = 1000,
  onEnd?: () => void
): [
  number,
  {
    start: (newTimeToCount?: number) => void;
    pause: () => void;
    resume: () => void;
    reset: () => void;
  }
] => {
  const [timeLeft, setTimeLeft] = React.useState(timeToCount);

  const timer = React.useRef<{
    started?: number;
    timeLeft?: number;
    lastInterval?: number;
    timeToCount?: number;
    requestId?: number;
  }>({});

  const run = ts => {
    if (!timer.current.started) {
      timer.current.started = ts;
      timer.current.lastInterval = ts;
    }

    const localInterval = Math.min(
      interval,
      timer.current.timeLeft || Infinity
    );
    if (ts - timer.current.lastInterval >= localInterval) {
      timer.current.lastInterval += localInterval;
      setTimeLeft(timeLeft => {
        timer.current.timeLeft = timeLeft - localInterval;
        return timer.current.timeLeft;
      });
    }

    if (ts - timer.current.started < timer.current.timeToCount) {
      timer.current.requestId = window.requestAnimationFrame(run);
    } else {
      timer.current = {};
      onEnd?.();
      setTimeLeft(0);
    }
  };

  const start = React.useCallback((newTimeToCount?: number) => {
    window.cancelAnimationFrame(timer.current.requestId);

    const _timeToCount =
      newTimeToCount !== undefined ? newTimeToCount : timeToCount;
    timer.current.started = null;
    timer.current.lastInterval = null;
    timer.current.timeToCount = _timeToCount;
    timer.current.requestId = window.requestAnimationFrame(run);

    setTimeLeft(_timeToCount);
  }, []);

  const pause = React.useCallback(() => {
    window.cancelAnimationFrame(timer.current.requestId);
    timer.current.started = null;
    timer.current.lastInterval = null;
    timer.current.timeToCount = timer.current.timeLeft;
  }, []);

  const resume = React.useCallback(() => {
    if (!timer.current.started && timer.current.timeLeft > 0) {
      window.cancelAnimationFrame(timer.current.requestId);
      timer.current.requestId = window.requestAnimationFrame(run);
    }
  }, []);

  const reset = React.useCallback(() => {
    if (timer.current.timeLeft) {
      window.cancelAnimationFrame(timer.current.requestId);
      timer.current = {};
      setTimeLeft(0);
    }
  }, []);

  const actions = React.useMemo(() => ({start, pause, resume, reset}), []);

  React.useEffect(() => {
    return () => window.cancelAnimationFrame(timer.current.requestId);
  }, []);

  return [timeLeft, actions];
};
