import * as React from 'react';
import {RefreshControl} from '@youtoken/ui.elements';
import {useObserver} from 'mobx-react';

type Feature = {
  isLoadingManually: boolean;
  refetchManually: () => Promise<any>;
};

// accepts N features (and resources for that matter)
// and return refresh control binded to to this features refresh
export const useAttachPullToRefreshToFeature = (
  ...features: Feature[]
): {refreshControl: React.ReactElement} => {
  if (features.length === 0) {
    throw new Error(
      `useAttachPullToRefreshToFeature requires at least one feature!`
    );
  }

  const isLoadingManually = useObserver(() => {
    return features.length > 1
      ? features.reduce(
          (acc, {isLoadingManually}) => acc || isLoadingManually,
          false
        )
      : features[0].isLoadingManually;
  });

  const onRefresh =
    features.length > 1
      ? () => Promise.all(features.map(f => f.refetchManually()))
      : features[0].refetchManually;

  return {
    refreshControl: (
      <RefreshControl refreshing={isLoadingManually} onRefresh={onRefresh} />
    ),
  };
};
