import * as React from 'react';
import {Box, ReanimatedBox} from '@youtoken/ui.primitives';
import {Platform} from 'react-native';
import {
  measure,
  runOnUI,
  useAnimatedRef,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

export const ToggleableSection: React.FC<{visible?: boolean}> = ({
  children,
  visible = false,
}) => {
  const aref = useAnimatedRef();
  const open = useSharedValue(false);
  const height = useSharedValue(0);

  const progress = useDerivedValue(() =>
    withSpring(open.value ? 1 : 0, {overshootClamping: true})
  );

  const style = useAnimatedStyle(() => ({
    height: height.value * progress.value + 1,
    opacity: progress.value,
  }));

  const handleOpen = React.useMemo(() => {
    return Platform.select({
      native: () => {
        runOnUI(() => {
          'worklet';
          height.value = measure(aref).height;
          open.value = true;
        })();
      },
      web: () => {
        // @ts-ignore
        height.value = aref.current!.offsetHeight!;
        open.value = true;
      },
    });
  }, []);

  const handleClose = React.useCallback(() => {
    open.value = false;
  }, []);

  React.useEffect(() => {
    if (visible) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [visible]);

  return (
    <Box>
      <ReanimatedBox style={style} overflow="hidden">
        <ReanimatedBox
          position="absolute"
          left={0}
          top={0}
          width="100%"
          ref={aref}
        >
          {children}
        </ReanimatedBox>
      </ReanimatedBox>
    </Box>
  );
};
