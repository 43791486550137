import {useCallback} from 'react';
import {Platform} from 'react-native';
import {
  GoogleSignin,
  GoogleOneTapSignIn,
} from '@react-native-google-signin/google-signin';
// kind of a hack, but the import doesn't work otherwise
import {useRecaptcha} from '@youtoken/ui.two-factor-auth-and-recaptcha/src/Recaptcha';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {TRANSPORT} from '@youtoken/ui.transport';

export const useGoogleAuth = () => {
  const {withToken} = useRecaptcha();
  const {handleSignInError} = AuthMeResource.use({});

  return useCallback(async (authToken: string) => {
    return withToken('sign_in', (recaptchaTokenSignIn: string) => {
      return TRANSPORT.API.post(`/auth/google/signin`, {
        accessCode: authToken,
        token: recaptchaTokenSignIn,
      });
    }).catch(handleSignInError);
  }, []);
};

export const useGoogleAuthForNativePlatform = (clientId: string) => {
  return useCallback(() => {
    if (Platform.OS === 'ios') {
      return GoogleSignin.signIn();
    }
    return GoogleOneTapSignIn.presentExplicitSignIn({
      webClientId: clientId,
    });
  }, [clientId]);
};
