import React from 'react';
import {Popable} from 'react-native-popable';
import {Box, Text, useIsDesktop, useTheme} from '@youtoken/ui.primitives';
import {useCalculateBodyOffset} from '../utils/tooltipParams';
import {TooltipProps} from './types';

export const Tooltip: React.FC<TooltipProps> = ({
  content,
  contentProps,
  width,
  numberOfLines,
  onShow,
  children,
  horizontalOffset,
  caretPosition,
  bodyHorizontalOffset,
  ...props
}) => {
  const {colors} = useTheme();
  const isDesktop = useIsDesktop();
  const calculatedBodyOffset = useCalculateBodyOffset(bodyHorizontalOffset);

  const handleAction = React.useCallback(
    (visible: boolean) => {
      visible && onShow?.();
    },
    [onShow]
  );

  return (
    <Popable
      backgroundColor={colors['$ui-overlay']}
      wrapperStyle={{zIndex: 1}}
      content={
        <Box px={10} py={5} {...contentProps}>
          <Text
            variant="$body-02"
            color="$text-04"
            weight="regular"
            ellipsizeMode="tail" // NOTE: for native only, another mode type can crash on Android
            numberOfLines={numberOfLines} // NOTE: for native only
          >
            {content}
          </Text>
        </Box>
      }
      action={isDesktop ? 'hover' : 'press'}
      onAction={handleAction}
      caretPosition={caretPosition}
      horizontalOffset={horizontalOffset}
      bodyHorizontalOffset={calculatedBodyOffset}
      style={{
        width,
      }}
      position="top"
      strictPosition
      {...props}
    >
      {children}
    </Popable>
  );
};
