import * as React from 'react';
import {
  GestureResponderEvent,
  Linking,
  Platform,
  TextProps as NativeTextProps,
} from 'react-native';
import {openBrowserAsync} from 'expo-web-browser';
import {SENTRY} from '@youtoken/ui.sentry';
import {TouchableText, TextProps} from '@youtoken/ui.primitives';

const useUrlPressCallback = (
  url?: string,
  onPress?: (event: GestureResponderEvent) => void,
  useInAppBrowser?: boolean
) => {
  return React.useCallback(
    (event: GestureResponderEvent) => {
      onPress?.(event);

      if (url) {
        Platform.select({
          web: () => {
            window.open(url, '_blank', 'noopener,noreferrer');
          },
          native: () => {
            Linking.canOpenURL(url)
              .then(canOpen => {
                if (useInAppBrowser) {
                  return openBrowserAsync(url);
                }
                if (canOpen) {
                  return Linking.openURL(url);
                }
              })
              .catch((error: Error) => {
                SENTRY.capture(error, {
                  source: 'Linking.canOpenURL',
                  component: 'Link',
                  extra: {url},
                });
              });
          },
        })();
      }
    },
    [url, onPress, useInAppBrowser]
  );
};

export const Link: React.FC<
  TextProps & {
    url?: string;
    onPress?: (e: GestureResponderEvent) => void;
    underlined?: boolean;
    useInAppBrowser?: boolean;
  } & Pick<NativeTextProps, 'testID'>
> = ({
  url,
  useInAppBrowser,
  onPress,
  color = '$interactive-01',
  underlined,
  ...props
}) => {
  const handlePress = useUrlPressCallback(url, onPress, useInAppBrowser);

  return (
    <TouchableText
      onPress={handlePress}
      accessibilityRole="link"
      color={color}
      underlined={underlined}
      {...props}
    />
  );
};
