import * as React from 'react';
import {type RecaptchaMethods} from './utils/recaptchaMethods';
import {RecaptchaComponent} from './RecaptchaComponent';

const rejectIfNotInitialized = (
  method: string,
  ref?: React.MutableRefObject<RecaptchaComponent>
) => {
  if (!ref || !ref.current || !ref.current?.[method]) {
    return Promise.reject(
      new Error(`Cannot call ${method}; Recaptcha not initialized.`)
    );
  }
};

export let __GLOBAL_RECAPTCHA__: RecaptchaMethods & {initialized: boolean} = {
  requestToken: () => rejectIfNotInitialized('requestToken'),
  requestTokenV2: () => rejectIfNotInitialized('requestTokenV2'),
  requestTokenV3: () => rejectIfNotInitialized('requestTokenV3'),
  withToken: () => rejectIfNotInitialized('withToken'),
  withTokenV2: () => rejectIfNotInitialized('withTokenV2'),
  withTokenV3: () => rejectIfNotInitialized('withTokenV3'),
  initialized: false,
};

export const RecaptchaContext =
  React.createContext<typeof __GLOBAL_RECAPTCHA__>(__GLOBAL_RECAPTCHA__);

export const RecaptchaContextProvider: React.FC = ({children}) => {
  const recaptchaRef = React.useRef<RecaptchaComponent>(null);

  const value = {
    withToken: (action, callback) => {
      rejectIfNotInitialized('withToken', recaptchaRef);
      return recaptchaRef.current.withToken(action, callback);
    },
    withTokenV2: (action, callback) => {
      rejectIfNotInitialized('withTokenV2', recaptchaRef);
      return recaptchaRef.current.withTokenV2(action, callback);
    },
    withTokenV3: (action, callback) => {
      rejectIfNotInitialized('withTokenV3', recaptchaRef);
      return recaptchaRef.current.withTokenV3(action, callback);
    },
    requestToken: action => {
      rejectIfNotInitialized('requestToken', recaptchaRef);
      return recaptchaRef.current.requestToken(action);
    },
    requestTokenV2: action => {
      rejectIfNotInitialized('requestTokenV2', recaptchaRef);
      return recaptchaRef.current.requestTokenV2(action);
    },
    requestTokenV3: action => {
      rejectIfNotInitialized('requestTokenV3', recaptchaRef);
      return recaptchaRef.current.requestTokenV3(action);
    },
    initialized: true,
  };

  if (!__GLOBAL_RECAPTCHA__.initialized) {
    Object.keys(value).forEach(key => {
      __GLOBAL_RECAPTCHA__[key] = value[key];
    });

    __GLOBAL_RECAPTCHA__.initialized = true;
  }

  return (
    <RecaptchaContext.Provider value={value}>
      <RecaptchaComponent
        ref={v => {
          recaptchaRef.current = v;
        }}
      />
      {children}
    </RecaptchaContext.Provider>
  );
};

export const useRecaptcha = () => {
  const context = React.useContext(RecaptchaContext);

  if (!context) {
    throw new Error('useRecaptcha must be used within a RecaptchaContext');
  }

  return context;
};
