import {warning} from '@youtoken/ui.utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {type IComboboxItem} from '@youtoken/ui.combobox';
import {Country, CountryShort, fullCountriesList} from './fullCountriesList';
import {blacklistCodes} from './blackList';
import {whitelistCodes, whitelistCodesFormA} from './whiteList';

export const ISO3toISO2 = (iso3: string): string | undefined => {
  if (!iso3 || typeof iso3 !== 'string' || iso3.length !== 3) {
    warning(
      iso3 && typeof iso3 === 'string' && iso3.length === 3,
      `you passed something instead of 3-letter country code, undefined will be returned`,
      {
        iso3,
      }
    );

    return undefined;
  }

  const country = fullCountriesList.find(c => c.code3 === iso3);

  warning(
    country,
    `Cannot find country with given iso3, this is probably a typo`,
    {
      iso3,
    }
  );

  return country?.code2;
};

export const ISO2toISO3 = (iso2: string) => {
  if (!iso2 || typeof iso2 !== 'string' || iso2.length !== 2) {
    warning(
      iso2 && typeof iso2 === 'string' && iso2.length === 2,
      `you passed something instead of 2-letter country code, undefined will be returned`,
      {
        iso2,
      }
    );

    return undefined;
  }

  const country = fullCountriesList.find(c => c.code2 === iso2);

  warning(country, `Cannot find country with iso2, this is probably a typo`, {
    iso2,
  });

  return country?.code3;
};

export const countriesList: Country[] = fullCountriesList.filter(
  country => !blacklistCodes.includes(country.code3)
);

export const countriesListFormA: Country[] = fullCountriesList.filter(country =>
  whitelistCodesFormA.includes(country.code3)
);

export const countriesListSignUp: Country[] = fullCountriesList.filter(
  country => whitelistCodes.includes(country.code3)
);

export const countriesListVerifyAddress: Country[] = fullCountriesList.filter(
  country => whitelistCodes.includes(country.code3)
);

export const getCountriesListLocalized = (list: Country[]): CountryShort[] => {
  return list
    .map(country => ({
      code: country.code3,
      phoneCode: country.phone,
      // @ts-ignore
      name: i18n.t_countries(country.code3),
    }))
    .sort(sortInLang);
};

function sortInLang(x: CountryShort, y: CountryShort) {
  return x.name.localeCompare(y.name, i18n.language, {ignorePunctuation: true});
}

export const groupCountriesByFirstLetter = (list: CountryShort[]) => {
  return list.reduce((acc: any, country: CountryShort) => {
    const firstLetter = country.name.substr(0, 1);

    if (!acc.hasOwnProperty(firstLetter)) {
      acc[firstLetter] = [];
    }

    acc[firstLetter].push(country);

    return acc;
  }, {});
};

export const formatCountriesForCombobox = (
  list: Country[]
): IComboboxItem[] => {
  return list.map(country => ({
    label: country.phone,
    value: country.phone,
    key: country.code3,
    keywords: country.name,
    extra: {
      labelShort: country.phone,
      labelLong: [country.phone, country.name].join(' '),
    },
  }));
};
