import {warning} from '@youtoken/ui.utils';

export const coinOrders = [
  // still add first demo tickers
  'ybtc',
  'yusd',
  //
  'chf',
  'eur',
  'gbp',
  'rub',
  'usd',
  'bonus',
  'usdt',
  'usdc',
  'busd',
  'dai',
  'tusd',
  'usdp',
  'husd',
  'eurs',
  'btc',
  'eth',
  'bnb',
  'mhix',
  'wxt',
  'mav',
  'gyen',
  'nzds',
  'xchf',
  'sol',
  'xrp',
  'ada',
  'luna',
  'avax',
  'dot',
  'doge',
  'matic',
  'ltc',
  'uni',
  'link',
  'bch',
  'trx',
  'xlm',
  'ethw',
  'ftt',
  'vet',
  'near',
  'fil',
  'atom',
  'egld',
  'icp',
  'theta',
  'xtz',
  'xmr',
  'ftm',
  'eos',
  'cake',
  'btt',
  'bttc',
  'aave',
  'bsv',
  'mkr',
  'sgb',
  'ht',
  'bat',
  'dash',
  'yfi',
  'comp',
  '1inch',
  'omg',
  'bnt',
  'zil',
  'sushi',
  'zrx',
  'snx',
  'srm',
  'paxg',
  'rep',
  'flr',
  'taud',
  'tgbp',
  'tcad',
  // still simple added, need take info, tickers not exist in task
  'hot',
  'pax',
  'shib',
  //
  'mana',
  'sand',
  'axs',
  'ilv',
  'gala',

  // ape
  'ape',

  // gmt
  'gmt',
  'apt',
  'grt',
  'agix',
  'fet',
  'thkd',
  'xaut',
  'pepe',
  'pyusd',
  'floki',
  'arb',
  'sui',
  'brz',
  'wld',
  'ton',
  'algo',
  'inj',
  'op',
  'rlb',
  'jup',
  'bonk',
  'mnt',
  'stx',
  'tia',
  'wif',
  'bonus',
  'meme',
  'not',
  'asi',
  'astr',
  'ena',
  'hbar',
  'strk',
  'w',
  'ray',
  'lista',
  'pendle',
  'zro',
];

export const hasCoinOrderIndex = (ticker: string) => {
  return getCoinOrderIndex(ticker) >= 0;
};

export const getCoinOrderIndex = (ticker: string) => {
  const index = coinOrders.indexOf(ticker);

  warning(index >= 0, `index for ticker not found`, {
    ticker,
  });

  return index >= 0 ? index : coinOrders.length;
};
