import React from 'react';
import {Box, useActiveThemeType} from '@youtoken/ui.primitives';
import GoogleRecaptcha from 'react-google-recaptcha';

type RecaptchaViewProps = {
  siteKey: string;
  onSuccess: (token: string) => void;
  onError: (...args: any[]) => void;
};

export const RecaptchaView: React.FC<RecaptchaViewProps> = ({
  onError,
  onSuccess,
  siteKey,
}) => {
  const activeTheme = useActiveThemeType();

  return (
    <Box>
      <GoogleRecaptcha
        sitekey={siteKey}
        onExpired={onError}
        onError={onError}
        onChange={onSuccess}
        type="image"
        size="normal"
        theme={activeTheme}
      />
    </Box>
  );
};
