import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';

type DotPaginationProps = {
  count: number;
  activeIndex: number;
};

export const DotPagination: React.FC<DotPaginationProps & BoxProps> = ({
  count,
  activeIndex,
  ...boxProps
}) => {
  const dots = React.useMemo(() => Array.from(Array(count)), [count]);

  return (
    <Box
      width="100%"
      py={24}
      flexDirection="row"
      justifyContent="center"
      {...boxProps}
    >
      {dots.map((_, index) => {
        return (
          <Box
            key={index}
            width={6}
            height={6}
            borderRadius={3}
            backgroundColor={
              index === activeIndex ? '$interactive-01' : '$interactive-03'
            }
            ml={8}
          />
        );
      })}
    </Box>
  );
};
