import React from 'react';
import {Icon} from '@youtoken/ui.icons';
import {Tooltip} from './index';
import {type TooltipProps} from './Tooltip';
import {Platform} from 'react-native';
import {Box, type TColorTokens} from '@youtoken/ui.primitives';
import {calculateOffset} from './utils/tooltipParams';

export interface QuestionTooltipProps extends TooltipProps {
  content: string;
  width?: string | number;
  wrapperWidth?: string | number;
  nativeNumberOfLines?: number;
  iconColor?: keyof TColorTokens;
  iconSize?: number;
  testID?: string;
}

const defaultWidth = Platform.select({
  web: 280,
  default: undefined,
});

export const QuestionTooltip: React.FC<QuestionTooltipProps> = ({
  content,
  width = defaultWidth,
  wrapperWidth,
  nativeNumberOfLines,
  iconColor = '$text-03',
  iconSize = 16,
  caretPosition,
  bodyHorizontalOffset,
  testID,
  ...rest
}) => {
  const calculatedOffset = calculateOffset(caretPosition, iconSize);

  return (
    <Tooltip
      content={content}
      width={width}
      numberOfLines={nativeNumberOfLines}
      caretPosition={caretPosition}
      horizontalOffset={calculatedOffset}
      bodyHorizontalOffset={bodyHorizontalOffset}
      {...rest}
    >
      <Box width={wrapperWidth} alignItems="center" testID={testID}>
        <Icon name="question" color={iconColor} size={iconSize} />
      </Box>
    </Tooltip>
  );
};
